<template>
  <tab-main-view
    :loader="loading"
    :config="config"
    :item="showItem.item"
    :fetch="fetch"
    :module="'fleet'"
    :route="'vehicles'"
    :method="'patch'"
    :redirect-path="'/fleet/vehicles'"
    :initial-autocompletes="showItem.initialAutocompletes"
    :initial-tab="'basicInfo'"
    :item-id="this.$route.params.id"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TabMainView from '@/global/components/view-layouts/tab-layout/TabMainView.vue'
import { formatSqlDate, formatIsoDate, formatSqlDateTime } from '@/global/services/helpers/dates'

const { mapGetters, mapActions } = createNamespacedHelpers('fleet/vehicle')
export default {
  name: 'FleetVehicleView',

  components: {
    TabMainView
  },

  props: {
    module: {
      type: String,
      default: 'fleet'
    },
    model: {
      type: String,
      default: 'vehicle'
    }
  },

  data () {
    return {
      loading: true
    }
  },

  computed: {
    ...mapGetters(['showItem', 'viewConfig']),

    config () {
      return {
        mainViewConfig: {
          tabsConfig: {
            basicInfo: {
              label: this.$t('fleet/vehicle/basic_info_tab.tab_label'),
              editable: true,
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'image',
                            label: this.$t('fleet/vehicle/basic_info_tab.images_label'),
                            type: 'image_preview',
                            max_images: 3,
                            dialog_title: this.$t('fleet.vehicle') + ' - ' + this.showItem?.item?.registration,
                            visible: true,
                            editable: true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    columns: [
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'registration',
                            label: this.$t('fleet/vehicle.registration'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'label',
                            label: this.$t('fleet/vehicle.label'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'type',
                            label: this.$t('fleet/vehicle.type'),
                            type: 'autocomplete',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'vehicles/types'
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'color',
                            label: this.$t('fleet/vehicle.color'),
                            type: 'autocomplete',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'vehicles/colors'
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'group',
                            label: this.$t('fleet/vehicle.group'),
                            type: 'autocomplete',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'groups/autocomplete'
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'driver',
                            label: this.$t('fleet/vehicle.driver'),
                            type: 'autocomplete',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'people',
                              route: 'people/autocomplete'
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'tags',
                            label: this.$t('fleet.vehicle_tags'),
                            type: 'combobox',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'vehicle-tags/autocomplete'
                            },
                            visible: true,
                            editable: true
                          }
                        ]
                      },
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'status_icon',
                            label: this.$t('fleet/vehicle.status'),
                            type: 'image',
                            base_image_src: '/img/icons/icon_small_status_unknown.png',
                            base_path: '/img/icons/',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'gsm_signal_icon',
                            label: this.$t('fleet/vehicle.gsm_signal'),
                            type: 'image',
                            base_image_src: '/img/icons/icon_small_signal_0.png',
                            base_path: '/img/icons/',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'measured_battery_voltage',
                            label: this.$t('fleet/vehicle_attribute.battery_voltage'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'odometer',
                            label: this.$t('fleet/vehicle.odometer'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'last_update',
                            label: this.$t('fleet/vehicle.last_update'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'engine_ignition_lock',
                            label: this.$t('fleet/vehicle.engine_ignition_lock'),
                            callBackend: true,
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicles/' + this.$route.params.id + '/toggle-engine-ignition-lock',
                              method: 'post'
                            },
                            type: 'switch',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'tracker',
                            label: this.$t('fleet/vehicle.tracker'),
                            type: 'autocomplete',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'base',
                              route: 'tracker/autocomplete'
                            },
                            visible: true,
                            editable: true
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            },
            details: {
              label: this.$t('fleet/activity.vehicle_details'),
              editable: true,
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'number_of_seats',
                            label: this.$t('fleet/vehicle_attribute.number_of_seats'),
                            type: 'text',
                            field_type: 'number',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('number_of_seats'),
                            editable: this.viewConfig?.editable_fields?.includes('number_of_seats')
                          },
                          {
                            key: 'engine_ccm',
                            label: this.$t('fleet/vehicle_attribute.engine_ccm'),
                            type: 'text',
                            field_type: 'number',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('engine_ccm'),
                            editable: this.viewConfig?.editable_fields?.includes('engine_ccm')
                          },
                          {
                            key: 'engine_power',
                            label: this.$t('fleet/vehicle_attribute.engine_power'),
                            type: 'text',
                            field_type: 'number',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('engine_power'),
                            editable: this.viewConfig?.editable_fields?.includes('engine_power')
                          },
                          {
                            key: 'co2_emission',
                            label: this.$t('fleet/vehicle_attribute.co2_emission'),
                            type: 'text',
                            field_type: 'number',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('co2_emission'),
                            editable: this.viewConfig?.editable_fields?.includes('co2_emission')
                          },
                          {
                            key: 'euro_standard',
                            label: this.$t('fleet/vehicle_attribute.euro_standard'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('euro_standard'),
                            editable: this.viewConfig?.editable_fields?.includes('euro_standard')
                          },
                          {
                            key: 'battery_voltage',
                            label: this.$t('fleet/vehicle_attribute.battery_voltage'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('battery_voltage'),
                            editable: this.viewConfig?.editable_fields?.includes('battery_voltage')
                          },
                          {
                            key: 'oil_type',
                            label: this.$t('fleet/vehicle_attribute.oil_type'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('oil_type'),
                            editable: this.viewConfig?.editable_fields?.includes('oil_type')
                          },
                          {
                            key: 'antifreeze_type',
                            label: this.$t('fleet/vehicle_attribute.antifreeze_type'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('antifreeze_type'),
                            editable: this.viewConfig?.editable_fields?.includes('antifreeze_type')
                          },
                          {
                            key: 'tire_size',
                            label: this.$t('fleet/vehicle_attribute.tire_size'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('tire_size'),
                            editable: this.viewConfig?.editable_fields?.includes('tire_size')
                          },
                          {
                            key: 'manufacture_year',
                            label: this.$t('fleet/vehicle_attribute.manufacture_year'),
                            type: 'text',
                            field_type: 'number',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('manufacture_year'),
                            editable: this.viewConfig?.editable_fields?.includes('manufacture_year')
                          },
                          {
                            key: 'vin',
                            label: this.$t('fleet/vehicle_attribute.vin'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('vin'),
                            editable: this.viewConfig?.editable_fields?.includes('vin')
                          }
                        ]
                      },
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'garage_number',
                            label: this.$t('fleet/vehicle_attribute.garage_number'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            visible: this.viewConfig?.editable_fields?.includes('garage_number'),
                            editable: this.viewConfig?.editable_fields?.includes('garage_number')
                          },
                          {
                            key: 'empty_weight',
                            label: this.$t('fleet/vehicle_attribute.empty_weight'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: this.viewConfig?.editable_fields?.includes('empty_weight'),
                            editable: this.viewConfig?.editable_fields?.includes('empty_weight')
                          },
                          {
                            key: 'maximum_weight',
                            label: this.$t('fleet/vehicle_attribute.maximum_weight'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: this.viewConfig?.editable_fields?.includes('maximum_weight'),
                            editable: this.viewConfig?.editable_fields?.includes('maximum_weight')
                          },
                          {
                            key: 'load_capacity',
                            label: this.$t('fleet/vehicle_attribute.load_capacity'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: this.viewConfig?.editable_fields?.includes('load_capacity'),
                            editable: this.viewConfig?.editable_fields?.includes('load_capacity')
                          },
                          {
                            key: 'top_speed',
                            label: this.$t('fleet/vehicle_attribute.top_speed'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: this.viewConfig?.editable_fields?.includes('top_speed'),
                            editable: this.viewConfig?.editable_fields?.includes('top_speed')
                          },
                          {
                            key: 'length',
                            label: this.$t('fleet/vehicle_attribute.length'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: this.viewConfig?.editable_fields?.includes('length'),
                            editable: this.viewConfig?.editable_fields?.includes('length')
                          },
                          {
                            key: 'width',
                            label: this.$t('fleet/vehicle_attribute.width'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: this.viewConfig?.editable_fields?.includes('width'),
                            editable: this.viewConfig?.editable_fields?.includes('width')
                          },
                          {
                            key: 'extinguisher_expiry',
                            label: this.$t('fleet/vehicle_attribute.extinguisher_expiry'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'date',
                            visible: this.viewConfig?.editable_fields?.includes('extinguisher_expiry'),
                            editable: this.viewConfig?.editable_fields?.includes('extinguisher_expiry')
                          },
                          {
                            key: 'stop_threshold',
                            label: this.$t('fleet/vehicle_attribute.stop_threshold'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: this.viewConfig?.editable_fields?.includes('stop_threshold'),
                            editable: this.viewConfig?.editable_fields?.includes('stop_threshold')
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            },
            fuel: {
              label: this.$t('fleet/vehicle.fuel'),
              editable: true,
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'fuel_type',
                            label: this.$t('fleet/vehicle.fuel_type'),
                            type: 'autocomplete',
                            editable_icon: 'mdi-pencil',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'vehicles/fuel-types'
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'fuel_tank_capacity',
                            label: this.$t('fleet/vehicle.fuel_tank_capacity'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'idle_fuel_consumption',
                            label: this.$t('fleet/vehicle.idle_fuel_consumption'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'urban_fuel_consumption',
                            label: this.$t('fleet/vehicle.urban_fuel_consumption'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'open_road_fuel_consumption',
                            label: this.$t('fleet/vehicle.open_road_fuel_consumption'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'combined_fuel_consumption',
                            label: this.$t('fleet/vehicle.combined_fuel_consumption'),
                            type: 'text',
                            editable_icon: 'mdi-pencil',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            },
            cargoSpace: {
              label: this.$t('fleet/vehicle.cargo_space'),
              editable: true,
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'cargo_space_pallets',
                            label: this.$t('fleet/vehicle.cargo_space_pallets'),
                            type: 'text',
                            field_type: 'number',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'cargo_space_width',
                            label: this.$t('fleet/vehicle.cargo_space_width'),
                            type: 'text',
                            field_type: 'number',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'cargo_space_length',
                            label: this.$t('fleet/vehicle.cargo_space_length'),
                            type: 'text',
                            field_type: 'number',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'cargo_space_height',
                            label: this.$t('fleet/vehicle.cargo_space_height'),
                            type: 'text',
                            field_type: 'number',
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            },
            tripConfig: {
              label: this.$t('fleet/vehicle.trip_config'),
              editable: true,
              visible: this.showItem?.tabsPermissions?.tripConfig,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'trip_config',
                            label: this.$t('fleet/vehicle.trip_config'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'trip-configs'
                            },
                            editable_icon: 'mdi-pencil',
                            visible: true,
                            editable: true
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            },
            vehicle_can_bus: {
              label: this.$t('fleet/vehicle.canbus'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'can-bus',
                        namespace: 'fleet/vehicle'
                      }
                    ]
                  },
                  {
                    columns: [
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'canbus_vehicle_speed',
                            label: this.$t('fleet/vehicle.canbus_vehicle_speed'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_acceleration_pedal',
                            label: this.$t('fleet/vehicle.canbus_acceleration_pedal'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_fuel_consumed',
                            label: this.$t('fleet/vehicle.canbus_fuel_consumed'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_fuel_level_percentage',
                            label: this.$t('fleet/vehicle.canbus_fuel_level'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_engine_rpm',
                            label: this.$t('fleet/vehicle.canbus_engine_rpm'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_total_mileage',
                            label: this.$t('fleet/vehicle.canbus_total_mileage'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_fuel_level',
                            label: this.$t('fleet/vehicle.canbus_fuel_level'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_engine_temperature',
                            label: this.$t('fleet/vehicle.canbus_engine_temperature'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          }
                        ]
                      },
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'canbus_door_status',
                            label: this.$t('fleet/vehicle.canbus_door_status'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_cruise_control_active',
                            label: this.$t('fleet/vehicle.canbus_cruise_control_active'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_service_distance',
                            label: this.$t('fleet/vehicle.canbus_service_distance'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_brake_switch',
                            label: this.$t('fleet/vehicle.canbus_brake_switch'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_vin',
                            label: this.$t('fleet/vehicle_attribute.vin'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_driver1_state',
                            label: this.$t('fleet/vehicle.canbus_driver1_state'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_driver2_state',
                            label: this.$t('fleet/vehicle.canbus_driver2_state'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_driver1_card',
                            label: this.$t('fleet/vehicle.canbus_driver1_card'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          }
                        ]
                      },
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'canbus_driver2_card',
                            label: this.$t('fleet/vehicle.canbus_driver2_card'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_dipped_headlights',
                            label: this.$t('fleet/vehicle.canbus_dipped_headlights'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_full_beam_headlights',
                            label: this.$t('fleet/vehicle.canbus_full_beam_headlights'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_driver_seatbelt',
                            label: this.$t('fleet/vehicle.canbus_driver_seatbelt'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_passenger_seatbelt',
                            label: this.$t('fleet/vehicle.canbus_passenger_seatbelt'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_handbrake',
                            label: this.$t('fleet/vehicle.canbus_handbrake'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_oil_warning',
                            label: this.$t('fleet/vehicle.canbus_oil_warning'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_coolant_warning',
                            label: this.$t('fleet/vehicle.canbus_coolant_warning'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          }
                        ]
                      },
                      {
                        layout: 'label-value',
                        items: [
                          {
                            key: 'canbus_handbrake_warning',
                            label: this.$t('fleet/vehicle.canbus_handbrake_warning'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_engine_warning',
                            label: this.$t('fleet/vehicle.canbus_engine_warning'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_brake_pads_warning',
                            label: this.$t('fleet/vehicle.canbus_brake_pads_warning'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_esp_warning',
                            label: this.$t('fleet/vehicle.canbus_esp_warning'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_glow_plug_warning',
                            label: this.$t('fleet/vehicle.canbus_glow_plug_warning'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_adblue_level',
                            label: this.$t('fleet/vehicle.canbus_adblue_level'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          },
                          {
                            key: 'canbus_engine_worktime',
                            label: this.$t('fleet/vehicle.canbus_engine_worktime'),
                            type: 'text',
                            non_editable_icon: 'mdi-pencil-off',
                            visible: true,
                            editable: false
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            },
            readouts: {
              label: this.$t('fleet/vehicle.readouts'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        headers: [
                          {
                            text: this.$t('fleet/vehicle.readout_datetime'),
                            value: 'datetime',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle.readout_type'),
                            value: 'type',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle.readout_value'),
                            value: 'value',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle.readout_author'),
                            value: 'user',
                            align: 'left',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          datetime: {
                            type: 'custom_html',
                            html: (item) => `${
                              formatSqlDateTime(item.datetime)
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          type: {
                            type: 'custom_html',
                            html: (item) => `${
                              item.type && item.type.name ? item.type.name : ''
                            }`,
                            custom_value: (item) => {
                              return item
                            }
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: 'DODAJ NOVI REDAK',
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('fleet/vehicle.readouts'),
                        detailsConfig: {
                          buttons: [
                            {
                              type: 'save',
                              text: 'SPREMI',
                              prependIcon: 'mdi mdi-content-save-edit-outline',
                              color: '#145214'
                            }
                          ],
                          backButton: {
                            text: 'NAZAD',
                            color: '#750b20',
                            prependIcon: 'mdi-arrow-left-bold'
                          },
                          columns: [
                            {
                              items: [
                                {
                                  key: 'type_id',
                                  label: this.$t('fleet/vehicle.readout_type'),
                                  type: 'autocomplete',
                                  autocomplete_options: {
                                    module: 'fleet',
                                    route: 'vehicles/readout-types',
                                    custom_display_value: (item) => {
                                      return item.name
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'value',
                                  label: this.$t('fleet/vehicle.readout_value'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'fleet',
                            route: 'vehicle-readout',
                            parent_id_name: 'vehicle_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'datetime',
                            label: this.$t('fleet/vehicle.readout_datetime'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'type',
                            label: this.$t('fleet/vehicle.readout_type'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'vehicle-readout/types',
                              custom_display_value: (item) => {
                                return item.name
                              }
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'value',
                            label: this.$t('fleet/vehicle.readout_value'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'fleet',
                          route: 'vehicle-readout',
                          parent_id_name: 'vehicle_id'
                        }
                      }
                    ]
                  }
                ]
              }
            },
            documents: {
              label: this.$t('fleet/vehicle_document.documents'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        headers: [
                          {
                            text: this.$t('fleet/vehicle_document.name'),
                            value: 'name',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_document.identification_string'),
                            value: 'identification_string',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_document.issued'),
                            value: 'issued',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_document.issued_by'),
                            value: 'issued_by',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_document.valid_until'),
                            value: 'valid_until',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_document.images'),
                            value: 'images',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_document.note'),
                            value: 'note',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_document.status'),
                            value: 'status',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_document.alarm_on'),
                            value: 'alarm_on',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('base.actions'),
                            value: 'actions',
                            width: '10%',
                            align: 'center',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          issued: {
                            type: 'custom_html',
                            html: (item) => `${
                                formatSqlDate(item.issued)
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          valid_until: {
                            type: 'custom_html',
                            html: (item) => `${
                                formatSqlDate(item.valid_until)
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          images: {
                            type: 'image',
                            actions: {
                              delete: {
                                module: 'fleet',
                                route: 'vehicle-document/remove-media/',
                                method: 'delete',
                                parameter: 'uuid'
                              }
                            },
                            preview_dialog_title: (item) => {
                              return this.$t('fleet/vehicle.document') + ' - ' + (item && item.name ? item.name : '/')
                            }
                          },
                          status: {
                            type: 'custom_value',
                            replace_values: {
                              1: this.$t('fleet/vehicle_document.status_active'),
                              0: this.$t('fleet/vehicle_document.status_inactive')
                            }
                          },
                          alarm_on: {
                            type: 'boolean'
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: this.$t('base.add_new_row'),
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('fleet/vehicle_document.documents'),
                        actions: [
                          {
                            key: 'edit',
                            text: this.$t('base.edit'),
                            icon: 'mdi mdi-pencil'
                          },
                          {
                            key: 'send_email',
                            text: this.$t('fleet/vehicle/documents_tab.send_to_email_button_label'),
                            icon: 'mdi-email-arrow-right',
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-document-send-to-email/',
                              method: 'post',
                              _method: 'PATCH'
                            },
                            dialogTitle: this.$t('fleet/vehicle/documents_tab.email_dialog_title'),
                            dialogFields: [
                              {
                                key: 'email',
                                type: 'text',
                                rules: ['required', 'email'],
                                text_label: 'Email',
                                visible: true,
                                editable: true
                              },
                              {
                                key: 'message',
                                type: 'text',
                                text_label: this.$t('fleet/vehicle/documents_tab.email_text'),
                                visible: true,
                                editable: true
                              }
                            ]
                          },
                          {
                            key: 'deactivate',
                            text: this.$t('base.deactivate'),
                            parameter: 'status',
                            icon: 'mdi-close-thick',
                            dialogMessage: this.$t('fleet/vehicle/documents_tab.deactivate_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/documents_tab.deactivate_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-document-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'activate',
                            text: this.$t('base.activate'),
                            parameter: 'status',
                            icon: 'mdi-check-bold',
                            dialogMessage: this.$t('fleet/vehicle/documents_tab.activate_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/documents_tab.deactivate_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-document-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'delete',
                            text: this.$t('base.delete'),
                            icon: 'mdi mdi-delete',
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-document/',
                              method: 'delete'
                            },
                            dialogMessage: this.$t('fleet/vehicle/documents_tab.delete_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/documents_tab.delete_dialog_title')
                          }
                        ],
                        detailsConfig: {
                          columns: [
                            {
                              items: [
                                {
                                  key: 'name',
                                  label: this.$t('fleet/vehicle_document.name'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'identification_string',
                                  label: this.$t('fleet/vehicle_document.identification_string'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'issued',
                                  label: this.$t('fleet/vehicle_document.issued'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'issued_by',
                                  label: this.$t('fleet/vehicle_document.issued_by'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle/documents_tab.uploaded_images_label'),
                                  type: 'images_preview',
                                  read_src_from: 'original_url',
                                  actions: {
                                    delete: {
                                      module: 'fleet',
                                      route: 'vehicle-document/remove-media/',
                                      method: 'delete',
                                      parameter: 'uuid'
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                }
                              ]
                            },
                            {
                              items: [
                                {
                                  key: 'valid_until',
                                  label: this.$t('fleet/vehicle_document.valid_until'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle_document.images'),
                                  type: 'image_upload',
                                  max: 2,
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'note',
                                  label: this.$t('fleet/vehicle_document.note'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'alarm_on',
                                  label: this.$t('fleet/vehicle_document.alarm_on'),
                                  type: 'switch',
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'fleet',
                            route: 'vehicle-document',
                            parent_id_name: 'vehicle_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'name',
                            label: this.$t('fleet/vehicle_document.name'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'identification_string',
                            label: this.$t('fleet/vehicle_document.identification_string'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'issued',
                            label: this.$t('fleet/vehicle_document.issued'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'issued_by',
                            label: this.$t('fleet/vehicle_document.issued_by'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'valid_until',
                            label: this.$t('fleet/vehicle_document.valid_until'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'fleet',
                          route: 'vehicle-document',
                          parent_id_name: 'vehicle_id'
                        }
                      }
                    ]
                  }
                ]
              }
            },
            vehicle_inspections: {
              label: this.$t('fleet/vehicle_inspection.inspections'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        item_key: 'inspections',
                        headers: [
                          {
                            text: this.$t('fleet/vehicle_inspection.inspection_date'),
                            value: 'inspection_date',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_inspection.valid_until'),
                            value: 'valid_until',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_inspection.inspection_location'),
                            value: 'inspection_location',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_inspection.note'),
                            value: 'note',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_inspection.images'),
                            value: 'images',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_inspection.status'),
                            value: 'status',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_inspection.alarm_on'),
                            value: 'alarm_on',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('base.actions'),
                            value: 'actions',
                            width: '10%',
                            align: 'center',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          inspection_date: {
                            type: 'custom_html',
                            html: (item) => `${
                              formatSqlDate(item.inspection_date)
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          valid_until: {
                            type: 'custom_html',
                            html: (item) => `${
                              formatSqlDate(item.valid_until)
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          images: {
                            type: 'image',
                            actions: {
                              delete: {
                                module: 'fleet',
                                route: 'vehicle-inspection/remove-image/',
                                method: 'delete',
                                parameter: 'uuid'
                              }
                            },
                            preview_dialog_title: (item) => {
                              return this.$t('fleet/vehicle.inspection') + ' - ' + (item && item.inspection_date ? formatSqlDate(item.inspection_date) : '/')
                            }
                          },
                          status: {
                            type: 'custom_value',
                            replace_values: {
                              1: this.$t('fleet/vehicle_inspection.status_active'),
                              0: this.$t('fleet/vehicle_inspection.status_inactive')
                            }
                          },
                          alarm_on: {
                            type: 'boolean'
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: this.$t('base.add_new_row'),
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('fleet/vehicle_inspection.inspections'),
                        actions: [
                          {
                            key: 'edit',
                            text: this.$t('base.edit'),
                            icon: 'mdi mdi-pencil'
                          },
                          {
                            key: 'deactivate',
                            text: this.$t('base.deactivate'),
                            parameter: 'status',
                            icon: 'mdi-close-thick',
                            dialogMessage: this.$t('fleet/vehicle/vehicle_inspection_tab.deactivation_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_inspection_tab.deactivation_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-inspection-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'activate',
                            text: this.$t('base.activate'),
                            parameter: 'status',
                            icon: 'mdi-check-bold',
                            dialogMessage: this.$t('fleet/vehicle/vehicle_inspection_tab.activation_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_inspection_tab.activation_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-inspection-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'delete',
                            text: this.$t('base.delete'),
                            icon: 'mdi mdi-delete',
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-inspection/',
                              method: 'delete'
                            },
                            dialogMessage: this.$t('fleet/vehicle/vehicle_inspection_tab.delete_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_inspection_tab.delete_dialog_title')
                          }
                        ],
                        detailsConfig: {
                          columns: [
                            {
                              items: [
                                {
                                  key: 'inspection_date',
                                  label: this.$t('fleet/vehicle_inspection.inspection_date'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'valid_until',
                                  label: this.$t('fleet/vehicle_inspection.valid_until'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'inspection_location',
                                  label: this.$t('fleet/vehicle_inspection.inspection_location'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle/documents_tab.uploaded_images_label'),
                                  type: 'images_preview',
                                  read_src_from: 'original_url',
                                  actions: {
                                    delete: {
                                      module: 'fleet',
                                      route: 'vehicle-inspection/remove-image/',
                                      method: 'delete',
                                      parameter: 'uuid'
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                }
                              ]
                            },
                            {
                              items: [
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle_inspection.images'),
                                  type: 'image_upload',
                                  max: 2,
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'note',
                                  label: this.$t('fleet/vehicle_inspection.note'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'alarm_on',
                                  label: this.$t('fleet/vehicle_inspection.alarm_on'),
                                  type: 'switch',
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'fleet',
                            route: 'vehicle-inspection',
                            parent_id_name: 'vehicle_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'inspection_date',
                            label: this.$t('fleet/vehicle_inspection.inspection_date'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'valid_until',
                            label: this.$t('fleet/vehicle_inspection.valid_until'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'inspection_location',
                            label: this.$t('fleet/vehicle_inspection.inspection_location'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'note',
                            label: this.$t('fleet/vehicle_inspection.note'),
                            type: 'text',
                            visible: true,
                            editable: true
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'fleet',
                          route: 'vehicle-inspection',
                          parent_id_name: 'vehicle_id'
                        }
                      }
                    ]
                  }
                ]
              }
            },
            vehicle_insurances: {
              label: this.$t('fleet/vehicle_insurance.insurances'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        item_key: 'insurances',
                        headers: [
                          {
                            text: this.$t('fleet/vehicle_insurance.insurance_type'),
                            value: 'type',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.contract_number'),
                            value: 'contract_number',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.foreign_id'),
                            value: 'foreign_id',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.insurance_provider'),
                            value: 'insurance_provider',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.franchise_details'),
                            value: 'franchise_details',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.other_details'),
                            value: 'other_details',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.start_date'),
                            value: 'start_date',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.expiry_date'),
                            value: 'expiry_date',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.duration'),
                            value: 'duration',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.payment_type'),
                            value: 'payment_type',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.monthly_instalment'),
                            value: 'monthly_instalment',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.currency'),
                            value: 'currency',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.images'),
                            value: 'images',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.pdf'),
                            value: 'pdf',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.status'),
                            value: 'status',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_insurance.alarm_on'),
                            value: 'alarm_on',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('base.actions'),
                            value: 'actions',
                            width: '10%',
                            align: 'center',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          type: {
                            type: 'object',
                            display_value: 'description',
                            use_translation: true,
                            custom_value: (item) => {
                              return {
                                id: item.id,
                                name: this.$t(item.description)
                              }
                            }
                          },
                          start_date: {
                            type: 'custom_html',
                            html: (item) => `${
                              formatSqlDate(item.start_date)
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          expiry_date: {
                            type: 'custom_html',
                            html: (item) => `${
                              formatSqlDate(item.expiry_date)
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          status: {
                            type: 'custom_value',
                            replace_values: {
                              1: this.$t('fleet/vehicle_insurance.status_active'),
                              0: this.$t('fleet/vehicle_insurance.status_inactive')
                            }
                          },
                          currency: {
                            type: 'object',
                            display_value: 'name',
                            custom_value: (item) => {
                              return {
                                id: item.id,
                                name: this.$t(item.name)
                              }
                            }
                          },
                          images: {
                            type: 'image',
                            actions: {
                              delete: {
                                module: 'fleet',
                                route: 'vehicle-insurance/remove-image/',
                                method: 'delete',
                                parameter: 'uuid'
                              }
                            },
                            preview_dialog_title: (item) => {
                              return this.$t('fleet/activity.vehicle_insurances') + ' - ' + (item && item.type && item.type.description ? this.$t(item.type.description) : '/')
                            }
                          },
                          pdf: {
                            type: 'pdf',
                            actions: {
                              delete: {
                                module: 'fleet',
                                route: 'vehicle-insurance/remove-pdf/',
                                method: 'delete',
                                parameter: 'uuid'
                              }
                            },
                            preview_dialog_title: (item) => {
                              return this.$t('fleet/activity.vehicle_insurances') + ' - ' + (item && item.type && item.type.description ? this.$t(item.type.description) : '/')
                            }
                          },
                          alarm_on: {
                            type: 'boolean'
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: this.$t('base.add_new_row'),
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('fleet/vehicle_insurance.insurances'),
                        actions: [
                          {
                            key: 'edit',
                            text: this.$t('base.edit'),
                            icon: 'mdi mdi-pencil'
                          },
                          {
                            key: 'deactivate',
                            text: this.$t('base.deactivate'),
                            parameter: 'status',
                            icon: 'mdi-close-thick',
                            dialogMessage: this.$t('fleet/vehicle/vehicle_insurance_tab.deactivation_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_insurance_tab.deactivation_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-insurance-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'activate',
                            text: this.$t('base.activate'),
                            parameter: 'status',
                            icon: 'mdi-check-bold',
                            dialogMessage: this.$t('fleet/vehicle/vehicle_insurance_tab.activation_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_insurance_tab.activation_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-insurance-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'delete',
                            text: this.$t('base.delete'),
                            icon: 'mdi mdi-delete',
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-insurance/',
                              method: 'delete'
                            },
                            dialogMessage: this.$t('fleet/vehicle/vehicle_insurance_tab.delete_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_insurance_tab.delete_dialog_title')
                          }
                        ],
                        detailsConfig: {
                          columns: [
                            {
                              items: [
                                {
                                  key: 'type',
                                  label: this.$t('fleet/vehicle_insurance.insurance_type'),
                                  type: 'autocomplete',
                                  autocomplete_options: {
                                    module: 'fleet',
                                    route: 'vehicle-insurance/types',
                                    custom_display_value: (item) => {
                                      return this.$t(item.description)
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'contract_number',
                                  label: this.$t('fleet/vehicle_insurance.contract_number'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'foreign_id',
                                  label: this.$t('fleet/vehicle_insurance.foreign_id'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'insurance_provider',
                                  label: this.$t('fleet/vehicle_insurance.insurance_provider'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'franchise_details',
                                  label: this.$t('fleet/vehicle_insurance.franchise_details'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'other_details',
                                  label: this.$t('fleet/vehicle_insurance.other_details'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'start_date',
                                  label: this.$t('fleet/vehicle_insurance.start_date'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'expiry_date',
                                  label: this.$t('fleet/vehicle_insurance.expiry_date'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'duration',
                                  label: this.$t('fleet/vehicle_insurance.duration'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle/documents_tab.uploaded_images_label'),
                                  type: 'images_preview',
                                  read_src_from: 'original_url',
                                  actions: {
                                    delete: {
                                      module: 'fleet',
                                      route: 'vehicle-insurance/remove-image/',
                                      method: 'delete',
                                      parameter: 'uuid'
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                }
                              ]
                            },
                            {
                              items: [
                                {
                                  key: 'payment_type',
                                  label: this.$t('fleet/vehicle_insurance.payment_type'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'monthly_instalment',
                                  label: this.$t('fleet/vehicle_insurance.monthly_instalment'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'currency',
                                  label: this.$t('fleet/vehicle_insurance.currency'),
                                  type: 'autocomplete',
                                  autocomplete_options: {
                                    module: 'fleet',
                                    route: 'vehicle-insurance/currency-types'
                                  },
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle_insurance.images'),
                                  type: 'image_upload',
                                  max: 2,
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'pdf',
                                  label: this.$t('fleet/vehicle_insurance.pdf'),
                                  type: 'pdf_upload',
                                  max: 1,
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'alarm_on',
                                  label: this.$t('fleet/vehicle_insurance.alarm_on'),
                                  type: 'switch',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'pdf',
                                  label: this.$t('fleet/vehicle.uploaded_pdfs'),
                                  type: 'pdf_preview',
                                  read_src_from: 'original_url',
                                  actions: {
                                    delete: {
                                      module: 'fleet',
                                      route: 'vehicle-insurance/remove-pdf/',
                                      method: 'delete',
                                      parameter: 'uuid'
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'fleet',
                            route: 'vehicle-insurance',
                            parent_id_name: 'vehicle_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'type',
                            label: this.$t('fleet/vehicle_insurance.insurance_type'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'vehicle-insurance/types',
                              custom_display_value: (item) => {
                                return this.$t(item.description)
                              }
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'contract_number',
                            label: this.$t('fleet/vehicle_insurance.contract_number'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'foreign_id',
                            label: this.$t('fleet/vehicle_insurance.foreign_id'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'insurance_provider',
                            label: this.$t('fleet/vehicle_insurance.insurance_provider'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'franchise_details',
                            label: this.$t('fleet/vehicle_insurance.franchise_details'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'other_details',
                            label: this.$t('fleet/vehicle_insurance.other_details'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'start_date',
                            label: this.$t('fleet/vehicle_insurance.start_date'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'expiry_date',
                            label: this.$t('fleet/vehicle_insurance.expiry_date'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'duration',
                            label: this.$t('fleet/vehicle_insurance.duration'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'payment_type',
                            label: this.$t('fleet/vehicle_insurance.payment_type'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'monthly_instalment',
                            label: this.$t('fleet/vehicle_insurance.monthly_instalment'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'currency',
                            label: this.$t('fleet/vehicle_insurance.currency'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'vehicle-insurance/currency-types'
                            },
                            visible: true,
                            editable: true
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'fleet',
                          route: 'vehicle-insurance',
                          parent_id_name: 'vehicle_id'
                        }
                      }
                    ]
                  }
                ]
              }
            },
            vehicle_leasings: {
              label: this.$t('fleet/vehicle_leasing.leasings'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        item_key: 'leasings',
                        headers: [
                          {
                            text: this.$t('fleet/vehicle_leasing.leasing_type'),
                            value: 'type',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.contract_number'),
                            value: 'contract_number',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.foreign_id'),
                            value: 'foreign_id',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.leasing_provider'),
                            value: 'leasing_provider',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.leasing_value_without_tax'),
                            value: 'leasing_value_without_tax',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.leasing_value_with_tax'),
                            value: 'leasing_value_with_tax',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.leasing_tax_amount'),
                            value: 'leasing_tax_amount',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.vehicle_net_value'),
                            value: 'vehicle_net_value',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.vehicle_redemption_value'),
                            value: 'vehicle_redemption_value',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.monthly_instalment'),
                            value: 'monthly_instalment',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.duration'),
                            value: 'duration',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.interest_rate'),
                            value: 'interest_rate',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.participation_amount'),
                            value: 'participation_amount',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.warranty_type'),
                            value: 'warranty_type',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.exceeded_km_price'),
                            value: 'exceeded_km_price',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.other_details'),
                            value: 'other_details',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.start_date'),
                            value: 'start_date',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.vehicle_pickup_date'),
                            value: 'vehicle_pickup_date',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.expiry_date'),
                            value: 'expiry_date',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.payment_method'),
                            value: 'payment_method',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.currency'),
                            value: 'currency',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.images'),
                            value: 'images',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.pdf'),
                            value: 'pdf',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.status'),
                            value: 'status',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_leasing.alarm_on'),
                            value: 'alarm_on',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('base.actions'),
                            value: 'actions',
                            width: '10%',
                            align: 'center',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          type: {
                            type: 'object',
                            display_value: 'description',
                            use_translation: true,
                            custom_value: (item) => {
                              return {
                                id: item.id,
                                name: this.$t(item.description)
                              }
                            }
                          },
                          start_date: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.start_date ? formatSqlDate(item.start_date) : '-'
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          leasing_value_without_tax: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.leasing_value_without_tax ? item.leasing_value_without_tax + ' ' + item.currency.abbreviation : '-'
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          leasing_value_with_tax: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.leasing_value_with_tax ? item.leasing_value_with_tax + ' ' + item.currency.abbreviation : '-'
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          leasing_tax_amount: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.leasing_tax_amount ? item.leasing_tax_amount + ' ' + item.currency.abbreviation : '-'
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          vehicle_net_value: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.vehicle_net_value ? item.vehicle_net_value + ' ' + item.currency.abbreviation : '-'
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          vehicle_redemption_value: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.vehicle_redemption_value ? item.vehicle_redemption_value + ' ' + item.currency.abbreviation : '-'
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          monthly_instalment: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.monthly_instalment ? item.monthly_instalment + ' ' + item.currency.abbreviation : '-'
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          interest_rate: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.interest_rate ? item.interest_rate + ' %' : '-'
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          participation_amount: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.participation_amount ? item.participation_amount + ' ' + item.currency.abbreviation : '-'
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          exceeded_km_price: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.exceeded_km_price ? item.exceeded_km_price + ' ' + item.currency.abbreviation : '-'
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          expiry_date: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.expiry_date ? formatSqlDate(item.expiry_date) : '-'
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          vehicle_pickup_date: {
                            type: 'custom_html',
                            html: (item) => `${
                              item && item.vehicle_pickup_date ? formatSqlDate(item.vehicle_pickup_date) : '-'
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          status: {
                            type: 'custom_value',
                            replace_values: {
                              1: this.$t('fleet/vehicle_leasing.status_active'),
                              0: this.$t('fleet/vehicle_leasing.status_inactive')
                            }
                          },
                          currency: {
                            type: 'object',
                            display_value: 'name',
                            custom_value: (item) => {
                              return {
                                id: item.id,
                                name: item.name
                              }
                            }
                          },
                          images: {
                            type: 'image',
                            actions: {
                              delete: {
                                module: 'fleet',
                                route: 'vehicle-leasing/remove-image/',
                                method: 'delete',
                                parameter: 'uuid'
                              }
                            },
                            preview_dialog_title: (item) => {
                              return this.$t('fleet/activity.vehicle_leasing') + ' - ' + (item && item.type && item.type.description ? this.$t(item.type.description) : '/')
                            }
                          },
                          pdf: {
                            type: 'pdf',
                            actions: {
                              delete: {
                                module: 'fleet',
                                route: 'vehicle-leasing/remove-pdf/',
                                method: 'delete',
                                parameter: 'uuid'
                              }
                            },
                            preview_dialog_title: (item) => {
                              return this.$t('fleet/activity.vehicle_leasing') + ' - ' + (item && item.type && item.type.description ? this.$t(item.type.description) : '/')
                            }
                          },
                          alarm_on: {
                            type: 'boolean'
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: this.$t('base.add_new_row'),
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('fleet/vehicle_leasing.leasings'),
                        actions: [
                          {
                            key: 'edit',
                            text: this.$t('base.edit'),
                            icon: 'mdi mdi-pencil'
                          },
                          {
                            key: 'deactivate',
                            text: this.$t('base.deactivate'),
                            parameter: 'status',
                            icon: 'mdi-close-thick',
                            dialogMessage: this.$t('fleet/vehicle/vehicle_leasing_tab.deactivation_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_leasing_tab.deactivation_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-leasing-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'activate',
                            text: this.$t('base.activate'),
                            parameter: 'status',
                            icon: 'mdi-check-bold',
                            dialogMessage: this.$t('fleet/vehicle/vehicle_leasing_tab.activation_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_leasing_tab.activation_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-leasing-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'delete',
                            text: this.$t('base.delete'),
                            icon: 'mdi mdi-delete',
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-leasing/',
                              method: 'delete'
                            },
                            dialogMessage: this.$t('fleet/vehicle/vehicle_leasing_tab.delete_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_leasing_tab.delete_dialog_title')
                          }
                        ],
                        detailsConfig: {
                          columns: [
                            {
                              items: [
                                {
                                  key: 'type',
                                  label: this.$t('fleet/vehicle_leasing.leasing_type'),
                                  type: 'autocomplete',
                                  autocomplete_options: {
                                    module: 'fleet',
                                    route: 'vehicle-leasing/types',
                                    custom_display_value: (item) => {
                                      return this.$t(item.description)
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'contract_number',
                                  label: this.$t('fleet/vehicle_leasing.contract_number'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'foreign_id',
                                  label: this.$t('fleet/vehicle_leasing.foreign_id'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'leasing_provider',
                                  label: this.$t('fleet/vehicle_leasing.leasing_provider'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'leasing_value_without_tax',
                                  label: this.$t('fleet/vehicle_leasing.leasing_value_without_tax'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'leasing_value_with_tax',
                                  label: this.$t('fleet/vehicle_leasing.leasing_value_with_tax'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'leasing_tax_amount',
                                  label: this.$t('fleet/vehicle_leasing.leasing_tax_amount'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'vehicle_net_value',
                                  label: this.$t('fleet/vehicle_leasing.vehicle_net_value'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'vehicle_redemption_value',
                                  label: this.$t('fleet/vehicle_leasing.vehicle_redemption_value'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'monthly_instalment',
                                  label: this.$t('fleet/vehicle_leasing.monthly_instalment'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'duration',
                                  label: this.$t('fleet/vehicle_leasing.duration'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'interest_rate',
                                  label: this.$t('fleet/vehicle_leasing.interest_rate'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'participation_amount',
                                  label: this.$t('fleet/vehicle_leasing.participation_amount'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle/documents_tab.uploaded_images_label'),
                                  type: 'images_preview',
                                  read_src_from: 'original_url',
                                  actions: {
                                    delete: {
                                      module: 'fleet',
                                      route: 'vehicle-leasing/remove-image/',
                                      method: 'delete',
                                      parameter: 'uuid'
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                }
                              ]
                            },
                            {
                              items: [
                                {
                                  key: 'warranty_type',
                                  label: this.$t('fleet/vehicle_leasing.warranty_type'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'exceeded_km_price',
                                  label: this.$t('fleet/vehicle_leasing.exceeded_km_price'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'other_details',
                                  label: this.$t('fleet/vehicle_leasing.other_details'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'start_date',
                                  label: this.$t('fleet/vehicle_leasing.start_date'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'vehicle_pickup_date',
                                  label: this.$t('fleet/vehicle_leasing.vehicle_pickup_date'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'expiry_date',
                                  label: this.$t('fleet/vehicle_leasing.expiry_date'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'payment_method',
                                  label: this.$t('fleet/vehicle_leasing.payment_method'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'currency',
                                  label: this.$t('fleet/vehicle_leasing.currency'),
                                  type: 'autocomplete',
                                  autocomplete_options: {
                                    module: 'fleet',
                                    route: 'vehicle-leasing/currency-types'
                                  },
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle_leasing.images'),
                                  type: 'image_upload',
                                  max: 2,
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'pdf',
                                  label: this.$t('fleet/vehicle_leasing.pdf'),
                                  type: 'pdf_upload',
                                  max: 1,
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'alarm_on',
                                  label: this.$t('fleet/vehicle_leasing.alarm_on'),
                                  type: 'switch',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'pdf',
                                  label: this.$t('fleet/vehicle.uploaded_pdfs'),
                                  type: 'pdf_preview',
                                  read_src_from: 'original_url',
                                  actions: {
                                    delete: {
                                      module: 'fleet',
                                      route: 'vehicle-leasing/remove-pdf/',
                                      method: 'delete',
                                      parameter: 'uuid'
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'fleet',
                            route: 'vehicle-leasing',
                            parent_id_name: 'vehicle_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'type',
                            label: this.$t('fleet/vehicle_leasing.leasing_type'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'vehicle-leasing/types',
                              custom_display_value: (item) => {
                                return this.$t(item.description)
                              }
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'contract_number',
                            label: this.$t('fleet/vehicle_leasing.contract_number'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'foreign_id',
                            label: this.$t('fleet/vehicle_leasing.foreign_id'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'leasing_provider',
                            label: this.$t('fleet/vehicle_leasing.leasing_provider'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'leasing_value_without_tax',
                            label: this.$t('fleet/vehicle_leasing.leasing_value_without_tax'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'leasing_value_with_tax',
                            label: this.$t('fleet/vehicle_leasing.leasing_value_with_tax'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'leasing_tax_amount',
                            label: this.$t('fleet/vehicle_leasing.leasing_tax_amount'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'vehicle_net_value',
                            label: this.$t('fleet/vehicle_leasing.vehicle_net_value'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'vehicle_redemption_value',
                            label: this.$t('fleet/vehicle_leasing.vehicle_redemption_value'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'monthly_instalment',
                            label: this.$t('fleet/vehicle_leasing.monthly_instalment'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'duration',
                            label: this.$t('fleet/vehicle_leasing.duration'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'interest_rate',
                            label: this.$t('fleet/vehicle_leasing.interest_rate'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'participation_amount',
                            label: this.$t('fleet/vehicle_leasing.participation_amount'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'warranty_type',
                            label: this.$t('fleet/vehicle_leasing.warranty_type'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'exceeded_km_price',
                            label: this.$t('fleet/vehicle_leasing.exceeded_km_price'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'other_details',
                            label: this.$t('fleet/vehicle_leasing.other_details'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'start_date',
                            label: this.$t('fleet/vehicle_leasing.start_date'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'vehicle_pickup_date',
                            label: this.$t('fleet/vehicle_leasing.vehicle_pickup_date'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'expiry_date',
                            label: this.$t('fleet/vehicle_leasing.expiry_date'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'payment_method',
                            label: this.$t('fleet/vehicle_leasing.payment_method'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'currency',
                            label: this.$t('fleet/vehicle_leasing.currency'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              module: 'fleet',
                              route: 'vehicle-leasing/currency-types'
                            },
                            visible: true,
                            editable: true
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'fleet',
                          route: 'vehicle-leasing',
                          parent_id_name: 'vehicle_id'
                        }
                      }
                    ]
                  }
                ]
              }
            },
            vehicle_range_limits: {
              label: this.$t('fleet/vehicle_range_limit.range_limits'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        item_key: 'range_limits',
                        headers: [
                          {
                            text: this.$t('fleet/vehicle_range_limit.limit_type'),
                            value: 'limit_type',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_range_limit.input_date'),
                            value: 'input_date',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_range_limit.yearly_limit'),
                            value: 'yearly_limit',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_range_limit.monthly_limit'),
                            value: 'monthly_limit',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_range_limit.valid_until_km'),
                            value: 'valid_until_km',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_range_limit.valid_until_date'),
                            value: 'valid_until_date',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_range_limit.status'),
                            value: 'status',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_range_limit.alarm_on'),
                            value: 'alarm_on',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('base.actions'),
                            value: 'actions',
                            width: '10%',
                            align: 'center',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          input_date: {
                            type: 'custom_html',
                            html: (item) => `${
                              formatSqlDate(item.input_date)
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          valid_until_date: {
                            type: 'custom_html',
                            html: (item) => `${
                              formatSqlDate(item.valid_until_date)
                            }`,
                            custom_value: (item) => `${
                              formatIsoDate(item)
                            }`
                          },
                          status: {
                            type: 'custom_value',
                            replace_values: {
                              1: this.$t('fleet/vehicle_range_limit.status_active'),
                              0: this.$t('fleet/vehicle_range_limit.status_inactive')
                            }
                          },
                          alarm_on: {
                            type: 'boolean'
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: this.$t('base.add_new_row'),
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('fleet/vehicle_range_limit.range_limits'),
                        actions: [
                          {
                            key: 'edit',
                            text: this.$t('base.edit'),
                            icon: 'mdi mdi-pencil'
                          },
                          {
                            key: 'deactivate',
                            text: this.$t('base.deactivate'),
                            parameter: 'status',
                            icon: 'mdi-close-thick',
                            dialogMessage: this.$t('fleet/vehicle/vehicle_range_limit.deactivation_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_range_limit.deactivation_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-range_limit-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'activate',
                            text: this.$t('base.activate'),
                            parameter: 'status',
                            icon: 'mdi-check-bold',
                            dialogMessage: this.$t('fleet/vehicle/vehicle_range_limit.activation_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_range_limit.activation_dialog_title'),
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-range_limit-status/',
                              method: 'post',
                              _method: 'PATCH'
                            }
                          },
                          {
                            key: 'delete',
                            text: this.$t('base.delete'),
                            icon: 'mdi mdi-delete',
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-range_limit/',
                              method: 'delete'
                            },
                            dialogMessage: this.$t('fleet/vehicle/vehicle_range_limit.delete_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_range_limit.delete_dialog_title')
                          }
                        ],
                        detailsConfig: {
                          columns: [
                            {
                              items: [
                                {
                                  key: 'limit_type',
                                  label: this.$t('fleet/vehicle_range_limit.limit_type'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'input_date',
                                  label: this.$t('fleet/vehicle_range_limit.input_date'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'yearly_limit',
                                  label: this.$t('fleet/vehicle_range_limit.yearly_limit'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                }
                              ]
                            },
                            {
                              items: [
                                {
                                  key: 'valid_until_km',
                                  label: this.$t('fleet/vehicle_range_limit.valid_until_km'),
                                  type: 'text',
                                  field_type: 'number',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'valid_until_date',
                                  label: this.$t('fleet/vehicle_range_limit.valid_until_date'),
                                  type: 'date',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'alarm_on',
                                  label: this.$t('fleet/vehicle_range_limit.alarm_on'),
                                  type: 'switch',
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'fleet',
                            route: 'vehicle-range_limit',
                            parent_id_name: 'vehicle_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'limit_type',
                            label: this.$t('fleet/vehicle_range_limit.limit_type'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'input_date',
                            label: this.$t('fleet/vehicle_range_limit.input_date'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'yearly_limit',
                            label: this.$t('fleet/vehicle_range_limit.yearly_limit'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'valid_until_km',
                            label: this.$t('fleet/vehicle_range_limit.valid_until_km'),
                            type: 'text',
                            field_type: 'number',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'valid_until_date',
                            label: this.$t('fleet/vehicle_range_limit.valid_until_date'),
                            type: 'date',
                            field_type: 'date',
                            visible: true,
                            editable: true
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'fleet',
                          route: 'vehicle-range_limit',
                          parent_id_name: 'vehicle_id'
                        }
                      }
                    ]
                  }
                ]
              }
            },
            vehicle_services: {
              label: this.$t('fleet/vehicle.service_intervals'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        item_key: 'services',
                        headers: [
                          {
                            text: this.$t('fleet/vehicle_service.service_type'),
                            value: 'type',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_service.name'),
                            value: 'name',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_service.start_state'),
                            value: 'start_state',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_service.interval'),
                            value: 'interval',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_service.current_state'),
                            value: 'current_state',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_service.until_next_service'),
                            value: 'until_next_service',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_service.upcoming_service'),
                            value: 'upcoming_service',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('base.actions'),
                            value: 'actions',
                            width: '10%',
                            align: 'center',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          type: {
                            type: 'object',
                            display_value: 'description',
                            use_translation: true,
                            custom_value: (item) => {
                              return {
                                id: item.id,
                                name: this.$t(item.description)
                              }
                            }
                          },
                          start_state: {
                            type: 'custom_html',
                            html: (item) => `${
                              item.type.name === 'km' || item.type.name === 'hours'
                                ? item.start_state + ' ' + item.unit
                                : formatSqlDate(item.start_state)
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          interval: {
                            type: 'custom_html',
                            html: (item) => `${
                                item.interval + ' ' + item.unit
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          current_state: {
                            type: 'custom_html',
                            use_main_object: true,
                            html: (item, mainObject) => `${
                              item.type.name === 'km' || item.type.name === 'hours'
                                ? mainObject.odometer
                                : formatSqlDate(item.current_state)
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          until_next_service: {
                            type: 'custom_html',
                            use_main_object: true,
                            html: (item, mainObject) => `${
                              item.type.name === 'km' || item.type.name === 'hours'
                                ? (item.start_state + item.interval - mainObject.odometer_value) + ' ' + item.unit
                                : item.until_next_service + ' ' + item.unit
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          upcoming_service: {
                            type: 'custom_html',
                            html: (item) => `${
                              item.type.name === 'km' || item.type.name === 'hours'
                                ? (item.start_state + item.interval) + ' ' + item.unit
                                : formatSqlDate(item.upcoming_service)
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: this.$t('base.add_new_row'),
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('fleet/vehicle.service_intervals'),
                        actions: [
                          {
                            key: 'edit',
                            text: this.$t('base.edit'),
                            icon: 'mdi-pencil'
                          },
                          {
                            key: 'archive',
                            text: this.$t('base.archive'),
                            icon: 'mdi-archive',
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-service-archive/',
                              method: 'post',
                              _method: 'PATCH'
                            },
                            dialogMessage: this.$t('fleet/vehicle/vehicle_service_interval.archive_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_service_interval.archive_dialog_title'),
                            dialogFields: [
                              {
                                key: 'start_state',
                                label: 'Datum',
                                type: 'dependable',
                                depends_on: 'type',
                                value: 'name',
                                states: {
                                  km: {
                                    type: 'text',
                                    field_type: 'number',
                                    suffix: 'km',
                                    text_label: this.$t('fleet/vehicle_service.archive_service_input_label_km'),
                                    visible: true,
                                    editable: true
                                  },
                                  hours: {
                                    type: 'text',
                                    field_type: 'number',
                                    suffix: 'h',
                                    text_label: this.$t('fleet/vehicle_service.archive_service_input_label_h'),
                                    visible: true,
                                    editable: true
                                  },
                                  date: {
                                    type: 'date',
                                    text_label: this.$t('fleet/vehicle_service.archive_service_input_label_d'),
                                    suffix: this.$t('fleet/vehicle.days'),
                                    visible: true,
                                    editable: true
                                  }
                                },
                                visible: true,
                                editable: true
                              },
                              {
                                key: 'note',
                                type: 'text',
                                text_label: this.$t('fleet/vehicle_service.archive_service_note'),
                                visible: true,
                                editable: true
                              }
                            ]
                          },
                          {
                            key: 'delete',
                            text: this.$t('base.delete'),
                            icon: 'mdi-delete',
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-service/',
                              method: 'delete'
                            },
                            dialogMessage: this.$t('fleet/vehicle/vehicle_service_interval.delete_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_service_interval.delete_dialog_title')
                          }
                        ],
                        detailsConfig: {
                          columns: [
                            {
                              items: [
                                {
                                  key: 'type',
                                  label: this.$t('fleet/vehicle_service.service_type'),
                                  type: 'autocomplete',
                                  autocomplete_options: {
                                    depends_on: 'is_working_machine',
                                    states: {
                                      true: {
                                        module: 'fleet',
                                        route: 'vehicle-service/working-machine-service-types'
                                      },
                                      false: {
                                        module: 'fleet',
                                        route: 'vehicle-service/base-service-types'
                                      }
                                    },
                                    custom_display_value: (item) => {
                                      return this.$t(item.description)
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'name',
                                  label: this.$t('fleet/vehicle_service.name'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'start_state',
                                  label: this.$t('fleet/vehicle_service.start_state'),
                                  type: 'dependable',
                                  depends_on: 'type',
                                  value: 'name',
                                  states: {
                                    km: {
                                      type: 'text',
                                      field_type: 'number',
                                      suffix: 'km',
                                      visible: true,
                                      editable: true
                                    },
                                    hours: {
                                      type: 'text',
                                      field_type: 'number',
                                      suffix: 'h',
                                      visible: true,
                                      editable: true
                                    },
                                    date: {
                                      type: 'date',
                                      suffix: 'dan(a)',
                                      visible: true,
                                      editable: true
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'interval',
                                  label: 'Servisni interval',
                                  type: 'dependable',
                                  depends_on: 'type',
                                  value: 'name',
                                  states: {
                                    km: {
                                      type: 'text',
                                      field_type: 'number',
                                      suffix: 'km',
                                      visible: true,
                                      editable: true
                                    },
                                    hours: {
                                      type: 'text',
                                      field_type: 'number',
                                      suffix: 'h',
                                      visible: true,
                                      editable: true
                                    },
                                    date: {
                                      type: 'text',
                                      suffix: 'dan(a)',
                                      visible: true,
                                      editable: true
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'fleet',
                            route: 'vehicle-service',
                            parent_id_name: 'vehicle_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'type',
                            label: this.$t('fleet/vehicle_service.service_type'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              depends_on: 'is_working_machine',
                              states: {
                                true: {
                                  module: 'fleet',
                                  route: 'vehicle-service/working-machine-service-types'
                                },
                                false: {
                                  module: 'fleet',
                                  route: 'vehicle-service/base-service-types'
                                }
                              },
                              custom_display_value: (item) => {
                                return this.$t(item.description)
                              }
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'name',
                            label: this.$t('fleet/vehicle_service.name'),
                            type: 'text',
                            visible: true,
                            editable: true
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'fleet',
                          route: 'vehicle-service',
                          parent_id_name: 'vehicle_id'
                        }
                      }
                    ]
                  }
                ]
              }
            },
            vehicle_services_history: {
              label: this.$t('fleet/vehicle_service.services_history'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        item_key: 'services_history',
                        headers: [
                          {
                            text: this.$t('fleet/vehicle_service.service_type'),
                            value: 'type',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_service.name'),
                            value: 'name',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_service.archived_state'),
                            value: 'archived_state',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_service.archive_service_note'),
                            value: 'note',
                            align: 'left',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          type: {
                            type: 'object',
                            display_value: 'description',
                            use_translation: true,
                            custom_value: (item) => {
                              return {
                                id: item.id,
                                name: this.$t(item.description)
                              }
                            }
                          },
                          archived_state: {
                            type: 'custom_html',
                            html: (item) => `${
                              item.type.name === 'km' || item.type.name === 'hours'
                                ? item.archived_state + ' ' + item.unit
                                : formatSqlDate(item.start_state)
                            }`
                          }
                        },
                        title: this.$t('fleet/vehicle_service.services_history'),
                        table_filters: [
                          {
                            key: 'type',
                            label: this.$t('fleet/vehicle_service.service_type'),
                            type: 'autocomplete',
                            autocomplete_options: {
                              depends_on: 'is_working_machine',
                              states: {
                                true: {
                                  module: 'fleet',
                                  route: 'vehicle-service/working-machine-service-types'
                                },
                                false: {
                                  module: 'fleet',
                                  route: 'vehicle-service/base-service-types'
                                }
                              },
                              custom_display_value: (item) => {
                                return this.$t(item.description)
                              }
                            },
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'name',
                            label: this.$t('fleet/vehicle_service.name'),
                            type: 'text',
                            visible: true,
                            editable: true
                          },
                          {
                            key: 'note',
                            label: this.$t('fleet/vehicle_service.archive_service_note'),
                            type: 'text',
                            visible: true,
                            editable: true
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'fleet',
                          route: 'vehicle-service-history',
                          parent_id_name: 'vehicle_id'
                        }
                      }
                    ]
                  }
                ]
              }
            },
            vehicle_comments: {
              label: this.$t('fleet/vehicle_comment.comments'),
              visible: true,
              view: {
                rows: [
                  {
                    columns: [
                      {
                        layout: 'data-table',
                        item_key: 'comments',
                        headers: [
                          {
                            text: this.$t('fleet/vehicle_range_limit.input_date'),
                            value: 'created_at',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_comment.text'),
                            value: 'body',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_comment.images'),
                            value: 'images',
                            align: 'center',
                            sortable: false
                          },
                          {
                            text: this.$t('fleet/vehicle_comment.wrote_by'),
                            value: 'user',
                            align: 'left',
                            sortable: false
                          },
                          {
                            text: this.$t('base.actions'),
                            value: 'actions',
                            width: '10%',
                            align: 'center',
                            sortable: false
                          }
                        ],
                        display_data_config: {
                          created_at: {
                            type: 'custom_html',
                            html: (item) => `${
                              formatSqlDateTime(item.created_at)
                            }`,
                            custom_value: (item) => `${
                              item
                            }`
                          },
                          images: {
                            type: 'image',
                            actions: {
                              delete: {
                                module: 'fleet',
                                route: 'vehicle-comment/remove-image/',
                                method: 'delete',
                                parameter: 'uuid'
                              }
                            },
                            preview_dialog_title: (item) => {
                              return this.$t('fleet/activity.vehicle_comments') + ' - ' + (item && item.created_at ? formatSqlDateTime(item.created_at) : '/')
                            }
                          }
                        },
                        rowPointer: true,
                        buttons: [
                          {
                            text: this.$t('base.add_new_row'),
                            prependIcon: 'mdi mdi-plus-circle',
                            iconClass: 'success--text mr-3'
                          }
                        ],
                        title: this.$t('fleet/vehicle_comment.comments'),
                        actions: [
                          {
                            key: 'edit',
                            text: this.$t('base.edit'),
                            icon: 'mdi-pencil'
                          },
                          {
                            key: 'delete',
                            text: this.$t('base.delete'),
                            icon: 'mdi-delete',
                            apiConfig: {
                              module: 'fleet',
                              route: 'vehicle-comment/',
                              method: 'delete'
                            },
                            dialogMessage: this.$t('fleet/vehicle/vehicle_comment.delete_dialog_message'),
                            dialogTitle: this.$t('fleet/vehicle/vehicle_comment.delete_dialog_title')
                          }
                        ],
                        detailsConfig: {
                          columns: [
                            {
                              items: [
                                {
                                  key: 'body',
                                  label: this.$t('fleet/vehicle_comment.text'),
                                  type: 'text',
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle_comment.images'),
                                  type: 'image_upload',
                                  max: 2,
                                  visible: true,
                                  editable: true
                                },
                                {
                                  key: 'images',
                                  label: this.$t('fleet/vehicle/documents_tab.uploaded_images_label'),
                                  type: 'images_preview',
                                  actions: {
                                    delete: {
                                      module: 'fleet',
                                      route: 'vehicle-comment/remove-image/',
                                      method: 'delete',
                                      parameter: 'uuid'
                                    }
                                  },
                                  visible: true,
                                  editable: true
                                }
                              ]
                            }
                          ],
                          apiConfig: {
                            module: 'fleet',
                            route: 'vehicle-comment',
                            parent_id_name: 'vehicle_id'
                          }
                        },
                        table_filters: [
                          {
                            key: 'body',
                            label: this.$t('fleet/vehicle_comment.text'),
                            type: 'text',
                            visible: true,
                            editable: true
                          }
                        ],
                        pagination_numbers: [5, 10, 50, 100],
                        indexApiConfig: {
                          module: 'fleet',
                          route: 'vehicle-comment',
                          parent_id_name: 'vehicle_id'
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        },
        breadCrumbsConfig: {
          prependIcon: {
            value: 'mdi mdi-car'
          },
          delimeter: '>',
          parts: [
            {
              value: this.$t('fleet.fleet')
            },
            {
              value: this.$t('fleet.vehicles')
            },
            {
              value: this.showItem?.item?.registration
            }
          ]
        },
        buttons: {
          back: {},
          edit: {},
          cancel: {},
          save: {}
        },
        files_config: {
          image: {
            type: 'image'
          }
        },
        auto_refresh: {
          label: this.$t('base.automatic_refresh'),
          refresh_every_seconds: 15,
          module: 'fleet',
          route: 'vehicles/update-auto-refresh',
          parameter: 'auto_refresh',
          user_config_ui_view_name: 'fleet'
        },
        autocompletes_values: {
          assigned_to: {
            type: 'object',
            value: 'id'
          },
          color: {
            type: 'object',
            value: 'id'
          },
          driver: {
            type: 'object',
            value: 'id'
          },
          fuel_type: {
            type: 'object',
            value: 'id'
          },
          group: {
            type: 'object',
            value: 'id'
          },
          tracker: {
            type: 'object',
            value: 'id'
          },
          trip_config: {
            type: 'object',
            value: 'id'
          },
          type: {
            type: 'object',
            value: 'id'
          }
        }
      }
    }
  },

  async created () {
    await this.fetch(this.$route.params.id)
    this.loading = false
  },

  methods: {
    ...mapActions(['fetch'])
  }
}
</script>
